<template>
  <div class="kpi-bulk-container">
    <div class="cm-btn-align-right">
            <Button
              class="edit-btn"
              @click="setUpEditSection()"
              :label="$t(`Edit Details`)" :color="'primary'"
              :disabled="!(loggedUserHierarchyLevel === 2 && allowContractEdit)"
              v-if="!editSection"
            />
            <Button
              class="cancel-btn"
              @click="handleCancelBtnClick()"
              :type="'outlined'"
              :label="$t(`cancel`)" :color="'primary'"
              :disabled="!(loggedUserHierarchyLevel === 2 && allowContractEdit)"
              v-else
            />
        </div>
    <div v-if="showForm">
        <Form class = "float-left" :key="getFormName()" :name="getFormName()" :loadFormData="true" :getFormUrlParams="'idName=ReportingUnitId&idValue=' + reportingUnitId" :isEditing="this.editSection"/>
    </div>
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState, mapActions } from 'vuex'
import { isNullOrUndefined } from '../../../../shared/utils/Objects'

export default {
  name: 'KpiTabBulk',
  data: () => ({
    editSection: false
  }),
  components: {
    Form,
    Button
  },
  computed: {
    showForm () {
      return !(this.reportingUnitId === null || this.reportingUnitId === undefined)
    },
    ...mapState('ContractManagement', ['reportingUnitId', 'allowContractEdit', 'loggedUserHierarchyLevel'])
  },
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  methods: {
    setUpEditSection () {
      this.editSection = true
    },
    getFormName () {
      return (this.editSection) ? 'EditContractKPIBulk' : 'ViewContractKPIBulk'
    },
    handleCancelBtnClick () {
      this.editSection = false
    },
    formSavedSuccessfully ({ name, response }) {
      this.editSection = false
    },
    ...mapActions('ContractManagement', ['setReportingUnitId'])
  },
  async mounted () {
    if (!isNullOrUndefined(this.reportingUnitId)) {
      this.id = this.$route.params.id
      this.setReportingUnitId(this.id)
    }
  }
}
</script>

<style>
  .kpi-bulk-container {
    margin-top: 15px;
  }
</style>
